<template>
  <div class="links">
    <a :href="`${wpUrl}/terms/`" class="footer-link" target="_blank">Terms of Service</a>
    <a :href="`${wpUrl}/privacy-policy/`" class="footer-link" target="_blank">Privacy Policy</a>
<!--        <a :href="`${wpUrl}/cookies-policy/`" class="footer-link" target="_blank">Cookies Policy</a>-->
  </div>
</template>

<script>
export default {
  name: "PrivacyLinks",
  computed: {
    wpUrl() {
      return process.env.VUE_APP_WP_URL
    },
  }
}
</script>
