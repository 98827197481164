<template>
  <div class="auth-sidebar">
    <div class="content d-flex flex-1 flex-column justify-center align-center">
      <router-link :to="{name:'home'}">
        <img src="@/assets/img/logo/logo-white.svg" alt="einvestment, logo">
      </router-link>
      <p class="mt-14 mb-8 pr-13 pr-lg-0">Please make sure you are accessing the genuine website</p>
      <v-btn
        color="white"
        elevation="0"
        x-large
        class="font-weight-regular">
        <img src="@/assets/img/lock.svg" alt="lock" class="mr-3">
        <span>URL: <span class="color-primary">https://</span>{{ siteUrl }}</span>
      </v-btn>
      <div class="login-banner-img">
        <video class="fs-video lazy" muted autoplay loop>
          <source src="@/assets/img/gradient.webm" type="video/mp4">
        </video>
      </div>
    </div>
    <div class="footer">
      <div class="footer-links">
        <PrivacyLinks/>
      </div>
      <div class="copyright-info-block">
        einvestment © 2018-{{ new Date().getFullYear() }}
      </div>
    </div>
  </div>
</template>

<script>
import PrivacyLinks from "@/components/basic/PrivacyLinks"
export default {
  name: "AuthSidebar",
  components: {PrivacyLinks},
  computed: {
    siteUrl() {
      return process.env.VUE_APP_URL.replace(/^(http|https):\/\//, '')
    },
    wpUrl() {
      return process.env.VUE_APP_WP_URL.replace(/^(http|https):\/\//, '')
    },
  }
}
</script>

<style lang="scss" scoped>
@keyframes gradient {
  0% {
    background-position: 80% 0%;
  }
  50% {
    background-position: 20% 100%;
  }
  100% {
    background-position: 80% 0%;
  }
}

.auth-sidebar {
  color: #fff;
  background: var(--v-primary-base);
  max-width: 550px;
  width: 100%;
  min-height: 100vh;
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;

  .login-banner-img {
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    height: 70%;
    margin: 30px 0 0;
    img{
      position: relative;
      margin: auto;
      z-index: 5;
    }
    .login-banner-img-background{
      position: absolute;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }
    .login-banner-img-background-1{
      position: absolute;
      top: calc(50% - 20.7vh);
      bottom: calc(50% - 19.1vh);
      left: calc(50% - 170px);
      right: calc(50% - 168px);
      background: linear-gradient(180deg, rgba(195, 161, 112, 1) 0%, rgba(237, 212, 145, 1) 25%, rgba(229, 219, 194, 1) 50%, rgba(224, 224, 223, 1) 75%, rgba(177, 177, 177, 1) 100%);
      animation: gradient 10s infinite linear;
      background-size: 400%;
      z-index: 1;
      &::before{
        content: '';
        position: absolute;
        top: 10px;
        bottom: 10px;
        left: 10px;
        right: 10px;
        background-color: #237f71;
      }
    }
    .login-banner-img-background-2{
      position: absolute;
      top: calc(50% - 19vh);
      bottom: calc(50% - 17.4vh);
      left: calc(50% - 153px);
      right: calc(50% - 152px);
      background: linear-gradient(180deg, rgba(177, 177, 177, 1) 0%, rgba(224, 224, 223, 1) 25%, rgba(229, 219, 194, 1) 50%, rgba(237, 212, 145, 1) 75%, rgba(195, 161, 112, 1) 100%);
      animation: gradient 10s infinite linear;
      background-size: 400%;
      z-index: 2;
      &::before{
        content: '';
        position: absolute;
        top: 10px;
        bottom: 10px;
        left: 10px;
        right: 10px;
        background-color: #237f71;
      }
    }
  }

  @media (max-width: 1400px) {
    padding: 0 40px;
    max-width: 480px;
  }
}

.content {
  padding-top: 64px;

  .sidebar-header {
    margin-top: 122px;
  }

  .row-with-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    img {
      margin-right: 26px;
    }
  }
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #ffffff10;
  padding-top: 26px;
  position: sticky;

  .footer-links {
    padding: 12px 0;
  }

  .copyright-info-block {
    padding: 15px 0 23px 0;
  }

  .footer-link {
    color: #fff !important;
    border-right: 1px solid #fff;
    font-size: 16px;
    padding: 0 12px;
    display: inline-block;
    line-height: 16px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      border-right: none;
    }
  }
}

</style>
