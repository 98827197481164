<template>
  <v-footer
    class="app-bar"
    clipped-left
    color="primary"
    dark
    flat
  >
    <v-container class="d-sm-flex justify-space-between font-weight-medium text-center text-sm-left">
      <div class="copyright-info-block pa-sm-0 pb-10 pt-5">
        einvestment © 2018-{{ new Date().getFullYear() }}
      </div>
      <div class="footer-links pa-sm-0 pb-1 pt-4">
        <PrivacyLinks/>
      </div>
    </v-container>
  </v-footer>
</template>

<script>
import PrivacyLinks from "@/components/basic/PrivacyLinks"
export default {
  name: "BottomBar",
  components: {PrivacyLinks},
}
</script>

<style lang="scss" scoped>
.app-bar {
  .footer-link {
    color: #fff;
    padding: 0 33px;
    border-right: 1px solid #fff;
    line-height: 24px;

    @media screen and (max-width: 750px) {
      border: none;
      padding: 0 8px;
      display: inline-block;
      font-size: 12px;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      border-right: none;
    }
  }

  @media screen and (max-width: 600px) {
    .copyright-info-block {
      border-bottom: 1px solid #398C7F;
    }
  }
}
</style>
