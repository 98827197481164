<template>
  <div class="layout-container" :class="{sidebar}">
    <sidebar v-if="sidebar && $vuetify.breakpoint.width > 1100" />
    <top-bar v-if="sidebar ? $vuetify.breakpoint.width <= 1100 : true" />
    <div class="content">
      <slot> <!--CONTENT--></slot>
    </div>
    <bottom-bar v-if="sidebar ? $vuetify.breakpoint.width <= 1100 : true" />
  </div>
</template>

<script>
import TopBar from "../components/TopBar"
import BottomBar from "../components/BottomBar"
import Sidebar from "../components/auth/AuthSidebar"
import listenCurrentUserEvents from "../mixins/listenCurrentUserEvents"

export default {
  name: "front-layout",
  components: {
    TopBar,
    BottomBar,
    Sidebar
  },
  mixins: [
    listenCurrentUserEvents,
  ],
  props: {
    sidebar: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {}
  },
  beforeMount() {
    this.listenCurrentUserEvents()
  },
}
</script>

<style lang="scss" scoped>
.layout-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-height: 100vh;

  &.sidebar {
    flex-direction: row;

    @media (max-width: 1100px) {
      flex-direction: column;
    }

    .content {
      background: #F6F6F8 url("../assets/img/auth-bg-big.png") no-repeat center/cover;
    }
  }

  &:not(.sidebar) > * {
    width: 100%;
    align-self: start;
    flex: 0;
  }

  ::v-deep .app-bar {
    flex: 0;
  }

  ::v-deep .auth-sidebar {
    flex: 1 !important;
  }

  @media (max-width: 1100px) {
    & > * {
      width: 100%;
      align-self: start;
      flex: 0;
    }
  }

  .content {
    background: #F6F6F8 url("../assets/img/auth-bg.png") no-repeat center/cover;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    flex: 1;

    @media screen and (max-width: 768px) {
      display: block;
    }
  }
}

</style>
